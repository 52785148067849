import Stickyfill from 'stickyfilljs';
import {
  artistModal,
  BackgroundVideo,
  checkoutAsideButton,
  Collapse,
  datepickerField,
  drawer,
  navbar,
  Parallax,
  Range,
  slider,
  VideoPlayer,
} from '@rawbot/roger-dubuis-styleguide/dist/styleguide';
import { phoneField } from '@rawbot/roger-dubuis-styleguide/dist/phone';
import * as Accordion from '@phoenix/components/Accordion';
import Panel from '@maison/wrappers/Panel';
import Modal from '@maison/wrappers/Modal';

import {
  AnimateGroupReveal,
  Delayer,
  BackgroundVideo as SGPHXBackgroundVideo,
  Collapse as SGPHXCollapse,
  Halo,
  Header,
  helpers,
  Parallax as SGPHXParallax,
  PhxToaster,
  ProductBanner,
  slider as SGPHXSlider,
  Splitting,
  StackedInteractiveMedia,
  Stripes,
  VideoPlayer as SGPHXVideoPlayer,
} from '@phx-styleguides/rdu';

const Tooltip = () => {};

export {
  Accordion,
  AnimateGroupReveal,
  artistModal,
  BackgroundVideo,
  checkoutAsideButton,
  Collapse,
  datepickerField,
  Delayer,
  drawer,
  Halo,
  Header,
  helpers,
  navbar,
  Modal,
  Panel,
  phoneField,
  PhxToaster,
  Parallax,
  ProductBanner,
  Range,
  SGPHXBackgroundVideo,
  SGPHXCollapse,
  SGPHXSlider,
  SGPHXParallax,
  SGPHXVideoPlayer,
  slider,
  Splitting,
  StackedInteractiveMedia,
  Stickyfill,
  Stripes,
  Tooltip,
  VideoPlayer,
};
